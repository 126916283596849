/* eslint-disable react/display-name */
import React from 'react'
import { Input, Menu, Dropdown, Tooltip } from 'antd'
import { useMediaQuery } from 'react-responsive'
import {
  StarOutlined,
  StarFilled,
  SyncOutlined,
  SearchOutlined,
  MoreOutlined,
  LockFilled
} from '@ant-design/icons'
import AddIcon from '../../static/add-icon.js'
import TableComponent from '../table/Table'
import ModalComponent from '../modal/ListActionsModal'
import { AlternativeSpinner } from '../loading/Loading'
import EyeIcon from '../../static/eye-icon.js'
import EditIcon from '../../static/edit-icon.js'
import RemoveIcon from '../../static/remove-icon.js'
import { AddButton } from '../button/AddButton.js'
import { ResetButton } from '../button/ResetButton.js'
import { TableButtonsContainer } from './../container/TableButtonsContainer'
import { TableContainer } from '../container/TableContainer.js'
import { H2 } from '../h2/CustomH2.js'
import { TitleContainer } from './../container/TitleContainer'
import { reportKindOptions } from '../../utils/constants.js'
import { find, get } from 'lodash'

const DropdownMenu = ({
  record,
  history,
  permissionToCreateEditDelete,
  setModal,
  setModalData
}) => (
  <Menu style={{ borderRadius: '8px' }}>
    <Menu.Item>
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={() => {
          history.push(`/reports/${record.id}`)
        }}
      >
        <EyeIcon />
      </button>
    </Menu.Item>
    {record.draft &&
      permissionToCreateEditDelete && (
      <Menu.Item>
        <button
          style={{ border: 'none', background: 'transparent' }}
          onClick={() => history.push(`/reports/edit/${record.id}`)}
        >
          <EditIcon />
        </button>
      </Menu.Item>
    )}
    {permissionToCreateEditDelete && (
      <Menu.Item>
        <button
          style={{ border: 'none', background: 'transparent' }}
          onClick={() => {
            setModalData({
              ...record
            })
            setModal(true)
          }}
        >
          <RemoveIcon />
        </button>
      </Menu.Item>
    )}
  </Menu>
)

const ReportsView = props => {
  const [showModal, setModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({})
  const [titleFilter, setTitleFilter] = React.useState('')
  const {
    data,
    history,
    userProfiles,
    deleteReportRequest,
    fetchReportRequest,
    loadingReports,
    pagination,
    updateReportPagination,
    toggleFavoriteReport,
    user_id,
    kind = 'report',
    permissions_data
  } = props
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' })
  const handleConfirmDelete = async () => {
    await deleteReportRequest(modalData.id)
    await fetchReportRequest({
      'q[kind_eq]': kind,
      'page[number]': 1,
      'page[size]': pagination.pageSize,
      'q[s]': 'created_at desc'
    })
    setModal(false)
    setModalData({})
  }
  const handleCancelDelete = () => {
    setModal(false)
    setModalData({})
  }
  const handleSizeChange = (current, size) => {
    if (size !== pagination.pageSize) {
      current = 1
    }
    updateReportPagination({ current, pageSize: size })
    fetchReportRequest({
      'q[kind_eq]': kind,
      'page[number]': current,
      'page[size]': size,
      'q[s]': 'created_at desc'
    })
  }
  const toggleFavoriteReportMethod = async ({ id, favorite }) => {
    await toggleFavoriteReport({ id, favorite })
    await fetchReportRequest({
      'q[kind_eq]': kind,
      'page[number]': 1,
      'page[size]': pagination.pageSize,
      'q[s]': 'created_at desc'
    })
  }
  const permissionToCreateEditDelete =
    userProfiles?.includes('superuser') || userProfiles?.includes('admin')
  const columns = [
    {
      title: '',
      dataIndex: 'favorite',
      align: 'center',
      width: '3em',
      render: (text, record, index) => {
        return (
          <div>
            {record.favorited ? (
              <StarFilled
                style={{ color: '#DEBA24' }}
                onClick={() =>
                  toggleFavoriteReportMethod({ id: record.id, favorite: false })
                }
              />
            ) : (
              <StarOutlined
                style={{ color: '#DEBA24' }}
                onClick={() =>
                  toggleFavoriteReportMethod({ id: record.id, favorite: true })
                }
              />
            )}
          </div>
        )
      }
    },
    {
      title: () => {
        return <span> Title </span>
      },
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => {
        const find_permission = find(permissions_data, pd => pd.report_id.toString() === record.id)
        const is_created_by_current_user = record.created_by_id.toString() === user_id.toString()
        return (
          <>
            <span style={{ color: '#4A55A5', fontWeight: 'bold' }}>
              {' '}
              {record.title}{' '}
            </span>
            {!permissionToCreateEditDelete && record.private ? !is_created_by_current_user && !get(find_permission, 'allow_read_private') && <Tooltip title='Visão disponível apenas para Sharpen'><LockFilled /></Tooltip> : null}
          </>
        )
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            User{' '}
          </span>
        )
      },
      key: 'createdBy',
      render: (_, record) => {
        return (
          <span style={{ width: 'fit-content' }}>{record.created_by.name}</span>
        )
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            Created at{' '}
          </span>
        )
      },
      key: 'created',
      render: (_, record) => {
        let date = new Date(record.created_at)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let dt = date.getDate()

        if (dt < 10) {
          dt = '0' + dt
        }
        if (month < 10) {
          month = '0' + month
        }
        return (
          <span>
            {month + '/' + dt + '/' + year}
          </span>
        )
      }
    },
    {
      title: () => {
        return <span> Actions </span>
      },
      key: 'actions',
      render: (_, record, index) => {
        const find_permission = find(permissions_data, pd => pd.report_id.toString() === record.id)
        const is_created_by_current_user = record.created_by_id.toString() === user_id.toString()
        return (<div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 0.33fr)',
            marginInline: 0
          }}
        >
          <ModalComponent
            visible={showModal}
            handleOk={handleConfirmDelete}
            handleCancel={handleCancelDelete}
            modalData={modalData}
            onOkText='Delete'
            onOkButtonProps={{ danger: true }}
          />
          <button
            style={{
              border: 'none',
              display: !permissionToCreateEditDelete && record.private ? !is_created_by_current_user && !get(find_permission, 'allow_read_private') && 'none' : 'initial',
              background: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => {
              history.push(`/reports/${record.id}`)
            }}
          >
            <EyeIcon />
          </button>

          {permissionToCreateEditDelete || user_id === record.created_by_id ? (
            <button
              style={{
                border: 'none',
                background: 'transparent',
                cursor: 'pointer'
              }}
              onClick={() =>
                history.push(`/reports/form`, {
                  report_id: record.id,
                  kind: record.kind
                })
              }
            >
              <EditIcon />
            </button>
          ) : (
            <div />
          )}
          {permissionToCreateEditDelete && (
            <button
              style={{
                border: 'none',
                background: 'transparent',
                cursor: 'pointer'
              }}
              onClick={() => {
                setModalData({
                  ...record
                })
                setModal(true)
              }}
            >
              <RemoveIcon />
            </button>
          )}
        </div>)
      }
    }
  ]
  const mobileColumn = [
    {
      title: '',
      dataIndex: 'favorite',
      align: 'center',
      width: '3em',
      render: (text, record, index) => {
        return (
          <div>
            {record.favorited ? (
              <StarFilled
                style={{ color: '#DEBA24' }}
                onClick={() =>
                  toggleFavoriteReportMethod({ id: record.id, favorite: false })
                }
              />
            ) : (
              <StarOutlined
                style={{ color: '#DEBA24' }}
                onClick={() =>
                  toggleFavoriteReportMethod({ id: record.id, favorite: true })
                }
              />
            )}
          </div>
        )
      }
    },
    {
      title: () => {
        return <span> Reports </span>
      },
      render: (_, record, index) => {
        return <span style={{ fontWeight: 'bolder' }}> {record.title} </span>
      }
    },
    {
      title: () => {
        return (
          <span style={{ fontWeight: 'bold', color: 'rgb(94, 95, 96)' }}>
            {' '}
            Created at{' '}
          </span>
        )
      },
      key: 'created',
      render: (_, record) => {
        let date = new Date(record.created_at)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let dt = date.getDate()

        if (dt < 10) {
          dt = '0' + dt
        }
        if (month < 10) {
          month = '0' + month
        }
        return (
          <span>
            {month + '/' + dt + '/' + year}
          </span>
        )
      }
    },
    {
      title: () => {
        return <span> Actions </span>
      },
      key: 'actions',
      render: (_, record, index) => (
        <div style={{ paddingLeft: '30px' }}>
          <ModalComponent
            visible={showModal}
            handleOk={handleConfirmDelete}
            handleCancel={handleCancelDelete}
            modalData={modalData}
            onOkText='Delete'
            onOkButtonProps={{ danger: true }}
          />
          <Dropdown
            trigger={['click']}
            overlay={
              <DropdownMenu
                setModal={setModal}
                setModalData={setModalData}
                record={record}
                permissionToCreateEditDelete={permissionToCreateEditDelete}
                history={history}
              />
            }
          >
            <MoreOutlined index={index} style={{ fontSize: '2em' }} />
          </Dropdown>
        </div>
      )
    }
  ]
  const getColumns = isPhone ? mobileColumn : columns
  const reportKind = reportKindOptions.find(item => item.value === kind)
  const addReportButtonLabel =
    reportKind.value === 'report' ? '' : reportKind.label
  return (
    <TableContainer style={{ position: 'relative' }}>
      <TitleContainer style={{ display: 'flex' }}>
        <H2>{reportKind.label}s</H2>
        <TableButtonsContainer>
          <div
            style={{
              display: 'flex',
              gap: '8px'
            }}
          >
            <ResetButton style={{ background: '#E0E1E1' }}>
              <SyncOutlined
                onClick={() =>
                  fetchReportRequest({
                    'q[kind_eq]': kind,
                    'page[number]': 1,
                    'page[size]': pagination.pageSize,
                    'q[s]': 'created_at desc'
                  })
                }
              />
            </ResetButton>
            <Input
              placeholder='Search by title'
              value={titleFilter}
              onChange={e => setTitleFilter(e.target.value)}
              style={{ width: '70%' }}
              suffix={
                <SearchOutlined
                  onClick={() => {
                    fetchReportRequest({
                      'q[kind_eq]': kind,
                      'page[number]': 1,
                      'page[size]': pagination.pageSize,
                      'q[title_cont]': titleFilter,
                      'q[s]': 'created_at desc'
                    })
                  }}
                />
              }
            />
          </div>
          {permissionToCreateEditDelete && (
            <div>
              <AddButton
                style={{ background: '#E0E1E1' }}
                onClick={() => history.push('/reports/form', { kind: kind })}
              >
                <AddIcon />
                Add {addReportButtonLabel} report
              </AddButton>
            </div>
          )}
        </TableButtonsContainer>
      </TitleContainer>
      <TableComponent
        rowKey={record => record.id}
        pagination={{
          ...pagination,
          onShowSizeChange: handleSizeChange,
          onChange: handleSizeChange
        }}
        dataSource={data}
        loading={{
          spinning: loadingReports,
          indicator: <AlternativeSpinner />
        }}
        columns={getColumns}
        style={{
          padding: '0.75em 0'
        }}
        size='small'
      />
    </TableContainer>
  )
}

export default ReportsView
